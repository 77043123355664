body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.table-warning {
  background-color: yellow;
}

.container-header {
  z-index: 10;
  background-color: #112855;
  background-image: linear-gradient(135deg, var(--cassiopeia-color-primary) 0%, var(--cassiopeia-color-hover) 100%);
  position: relative;
  box-shadow: inset 0 5px 5px #00000008;
  color:#fff;
  
}
.position-sticky {
  position: sticky !important;
}

body.wrapper-fluid header > .grid-child, body.wrapper-fluid footer > .grid-child {
  padding-left: 2em;
  padding-right: 2em;
}
body.wrapper-fluid .grid-child {
  max-width: none;
}
.container-header .grid-child {
  padding: .5em;
    padding-right: 0.5em;
    padding-left: 0.5em;
}
.container-header .navbar-brand {
  font-size: 1rem;
}
.container-header .navbar-brand {
  color: #fff;
  margin-inline-end: auto;
  font-size: 2rem;
  display: inline-block;
  position: relative;
}